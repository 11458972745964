"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateHeight = exports["default"] = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.function.name.js");
var _i18n = require("../../i18n");
var _utils = require("../../utils");
var _excluded = ["name"];
var validateMetric = function validateMetric(valueInCentimeters, metric_min, metric_max) {
  if (metric_max === undefined || metric_max === '' || metric_max === null) {
    return valueInCentimeters < metric_min ? (0, _i18n.globalTranslate)('validations.heightInternational.customMetricMin', {
      min: Number(metric_min)
    }) : undefined;
  }
  if (metric_min === undefined || metric_min === '' || metric_min === null) {
    return valueInCentimeters > metric_max ? (0, _i18n.globalTranslate)('validations.heightInternational.customMetricMax', {
      max: Number(metric_max)
    }) : undefined;
  }
  var isInRange = valueInCentimeters >= metric_min && valueInCentimeters <= metric_max;
  return isInRange ? undefined : (0, _i18n.globalTranslate)('validations.heightInternational.customMetricMinMax', {
    min: Number(metric_min),
    max: Number(metric_max)
  });
};
var validateImperial = function validateImperial(valueInFeet, valueInInches, rest) {
  var _rest$imperial_inches, _rest$imperial_inches2;
  var imperial_feet_min = rest.imperial_feet_min,
    imperial_feet_max = rest.imperial_feet_max;
  var imperial_inches_min = (_rest$imperial_inches = rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) !== null && _rest$imperial_inches !== void 0 ? _rest$imperial_inches : 0;
  var imperial_inches_max = (_rest$imperial_inches2 = rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max) !== null && _rest$imperial_inches2 !== void 0 ? _rest$imperial_inches2 : 11;
  // Check for decimal places in feet
  if (String(valueInFeet).includes('.')) {
    return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialFeetDecimalPlaces');
  }

  // Check for decimal places in inches
  if (String(valueInInches).includes('.')) {
    return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialInchesDecimalPlaces');
  }

  // Validate against min and max boundaries
  var isFeetWithinRange = (0, _utils.isEmpty)(imperial_feet_min) && (0, _utils.isEmpty)(imperial_feet_max) ? true : (0, _utils.isEmpty)(imperial_feet_min) || imperial_feet_min === undefined ? Number(valueInFeet) <= imperial_feet_max : (0, _utils.isEmpty)(imperial_feet_max) || imperial_feet_max === undefined ? Number(valueInFeet) >= imperial_feet_min : Number(valueInFeet) >= imperial_feet_min && Number(valueInFeet) <= imperial_feet_max;
  var isInchesWithinRange = (0, _utils.isEmpty)(imperial_inches_min) && (0, _utils.isEmpty)(imperial_inches_max) ? true : (0, _utils.isEmpty)(imperial_inches_min) || imperial_inches_min === undefined ? Number(valueInInches) <= imperial_inches_max : (0, _utils.isEmpty)(imperial_inches_max) || imperial_inches_max === undefined ? Number(valueInInches) >= imperial_inches_min : Number(valueInInches) >= imperial_inches_min && Number(valueInInches) <= imperial_inches_max;
  if (!isFeetWithinRange && !(0, _utils.isEmpty)(valueInFeet) || !isInchesWithinRange && !(0, _utils.isEmpty)(valueInInches)) {
    var messageKey = (0, _utils.isEmpty)(imperial_feet_min) && (0, _utils.isEmpty)(imperial_feet_max) && !(0, _utils.isEmpty)(imperial_inches_min) && !(0, _utils.isEmpty)(imperial_inches_max) ? 'validations.heightInternational.customImperialMinMaxInchesBounds' : !(0, _utils.isEmpty)(imperial_feet_min) && !(0, _utils.isEmpty)(imperial_feet_max) && (0, _utils.isEmpty)(imperial_inches_min) && (0, _utils.isEmpty)(imperial_inches_max) ? 'validations.heightInternational.customImperialMinMaxFeetBounds' : (0, _utils.isEmpty)(imperial_feet_min) || imperial_feet_min === undefined ? 'validations.heightInternational.customImperialMax' : (0, _utils.isEmpty)(imperial_feet_max) || imperial_feet_max === undefined ? 'validations.heightInternational.customImperialMin' : 'validations.heightInternational.customImperialMinMax';
    return (0, _i18n.globalTranslate)(messageKey, {
      min: imperial_feet_min,
      max: imperial_feet_max,
      minInches: imperial_inches_min || 0,
      maxInches: imperial_inches_max || 11
    });
  }
  if ((0, _utils.isEmpty)(valueInFeet)) {
    var _messageKey = (0, _utils.isEmpty)(imperial_feet_min) && (0, _utils.isEmpty)(imperial_feet_max) ? 'validations.heightInternational.customImperialMinMaxFeetBoundsEmptyFeet' : !(0, _utils.isEmpty)(imperial_feet_min) && (0, _utils.isEmpty)(imperial_feet_max) ? 'validations.heightInternational.customImperialMin' : (0, _utils.isEmpty)(imperial_feet_min) && !(0, _utils.isEmpty)(imperial_feet_max) ? 'validations.heightInternational.customImperialMax' : 'validations.heightInternational.customImperialMinMax';
    return (0, _i18n.globalTranslate)(_messageKey, {
      min: imperial_feet_min,
      max: imperial_feet_max,
      minInches: imperial_inches_min,
      maxInches: imperial_inches_max
    });
  }
  if ((0, _utils.isEmpty)(valueInInches)) {
    return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialInchesEmpty');
  }

  // Check if feet are within standard boundaries
  if (!(0, _utils.isEmpty)(valueInInches) && (Number(valueInInches) < 0 || Number(valueInInches) >= 12)) {
    return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMaxInchesBounds', {
      minInches: imperial_inches_min || 0,
      maxInches: imperial_inches_max || 11
    });
  }

  // Return undefined if all validations pass
  return undefined;
};
var validateHeight = exports.validateHeight = function validateHeight(_ref) {
  var name = _ref.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value) {
    var measurementUnits = value === null || value === void 0 ? void 0 : value["".concat(name, "_units")];
    if (measurementUnits === 'centimeters') {
      var stringValue = String(value === null || value === void 0 ? void 0 : value[name]);
      if (stringValue.includes('.')) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.customMetricDecimalPlaces');
      }
      var valueInCentimeters = Number(stringValue);
      var metric_min = rest.metric_min,
        metric_max = rest.metric_max;
      return validateMetric(valueInCentimeters, metric_min, metric_max);
    } else {
      var _value$name, _value$name2, _value$name3, _value$name4;
      var valueInFeet = (0, _utils.isEmpty)(value === null || value === void 0 ? void 0 : (_value$name = value[name]) === null || _value$name === void 0 ? void 0 : _value$name.ft) ? undefined : value === null || value === void 0 ? void 0 : (_value$name2 = value[name]) === null || _value$name2 === void 0 ? void 0 : _value$name2.ft;
      var valueInInches = (0, _utils.isEmpty)(value === null || value === void 0 ? void 0 : (_value$name3 = value[name]) === null || _value$name3 === void 0 ? void 0 : _value$name3.inch) ? undefined : value === null || value === void 0 ? void 0 : (_value$name4 = value[name]) === null || _value$name4 === void 0 ? void 0 : _value$name4.inch;
      return validateImperial(valueInFeet, valueInInches, rest);
    }
  };
};
var HeightValidations = function HeightValidations(props) {
  return [validateHeight(props)];
};
var _default = exports["default"] = HeightValidations;