// @ts-nocheck
import { useEffect, useRef } from 'react';
import Footer from './footer';
import Header from './header';
import LoadingIndicator from './loadingIndicator';
import { NotificationModal as Notification } from './NotificationModal';
import { connect } from 'react-redux';
import { actions } from '../redux';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import GlobalStyles from './globalStyle';
import BrandStyles from './brandStyles';
import { getIsWebview } from '../services';
import VerifyParticipantDataModal from './verifyParticipantDataModal';
import CookieBanner from './CookieBanner';
import LanguageProvider from '../contexts/LanguageContext';
import { useSetLocaleOverride } from '../hooks';
import Cognito from './cognito';
import { createCustomScriptTag } from 'src/utils/createCustomTag';

const Global = createGlobalStyle`
  ${GlobalStyles}
  ${(p) => p.theme.brand_name !== 'Achievement' && BrandStyles}
`;

const LayoutContainer = styled.div`
  min-height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
`;

const ModalBackgroundComponent = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  opacity: ${(props) => props.opacity};
  background-color: rgba(0, 0, 0, 0.6);
`;

const MainLayout = (props) => {
  const {
    children,
    participant,
    meta,
    loading,
    verifyParticipantData: { updateData, verified },
    setIsLoading,
    stylesLoaded,
  } = props;

  const { layout, status } = participant;
  const isWebview = getIsWebview();
  // during development mode and possibily other scenarios this component my rerender
  // if that happens use this ref to not keep creeating scripts in the body.
  const scriptInitRef = useRef();

  // If the url contains a query param called `locale_override`, it takes precedence over the `meta?.locale` props.
  const localOverride = useSetLocaleOverride();
  const locale = localOverride || meta?.locale;

  useEffect(() => {
    // Avoid defining custom scripts if the landing endpoint hasn't been fetched yet.
    if (!meta.slug) { return; }

    const scripts = createCustomScriptTag(meta?.brand?.custom_body, false);
    const scriptEls: Element[] = [];
    if (scripts && scripts.length > 0 && !scriptInitRef.current) {
      scripts.forEach((scriptData) => {
        const script = document.createElement('script');
        Object.keys(scriptData.attributes)?.forEach((attr) => {
          script[attr] = scriptData.attributes[attr];
        });
        script.innerHTML = scriptData.children.join('');
        scriptEls.push(script);
        document.body.appendChild(script);
      });
      scriptInitRef.current = true;
    }
    return () => {
      scriptEls.forEach((el) => document.body.removeChild(el));
    };
  }, [meta.slug, meta?.brand?.custom_body]);

  return (
    <Cognito setIsLoading={setIsLoading}>
      <LanguageProvider locale={locale}>
        <ThemeProvider theme={meta.brand || {}}>
          <LayoutContainer>
            <Global />
            <ModalProvider backgroundComponent={ModalBackgroundComponent}>
              {!isWebview && stylesLoaded && (
                <Header
                  enrollmentLogOut={props.logoutUser}
                  meta={meta}
                  status={status}
                  study_status={meta.status}
                  layout={layout}
                  setIsLoading={setIsLoading}
                />
              )}
              <Notification />
              {/*
               * The children are the only thing that need to be conditionally
               * rendered since that's the only thing that depends on the current
               * page's Redux state.
               */}
              <main id="content">
                {loading ? <LoadingIndicator /> : children}
              </main>
              {!isWebview && stylesLoaded && <Footer {...meta} />}
              {updateData && (
                <VerifyParticipantDataModal
                  showModal={updateData}
                  verified={verified}
                  participant={participant}
                />
              )}
            </ModalProvider>
          </LayoutContainer>
          {/* In Study App Web Views hide the cookie banner SI-6246 */}
          {!loading && !meta.gtm_id_cookie_banner && !isWebview && (
            <CookieBanner />
          )}
        </ThemeProvider>
      </LanguageProvider>
    </Cognito>
  );
};

export default connect(
  ({ participant, meta }) => ({
    participant,
    meta,
  }),
  (dispatch) => ({
    logoutUser: (user) => dispatch(actions.logoutUser(user)),
    getMeta: (auth_token, enrollment_identifier) =>
      dispatch(actions.getMeta(auth_token, enrollment_identifier)),
    getUser: (enrollment_id, auth_token) =>
      dispatch(actions.getUser(enrollment_id, auth_token)),
  }),
)(MainLayout);
