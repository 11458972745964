// @ts-nocheck
import React, { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import styled, { css, withTheme } from 'styled-components';
import { Button } from '@evidation/form-elements';
import LogoAcm from '../../assets/achievement/logo';
import Globe from '../../assets/globe';
import PPMILogo from '../../assets/ppmi/logo';
import { useSetLocaleOverride, useTranslated } from '../../hooks';
import BasicStyledModal from '../styledModal';
import { isMobile } from 'react-device-detect';
import { getDisplayTextFromLocale } from '../../utils';
import { NavLinkStyle, NavLinkDiv, NavLinkButton } from '../NavLink.styles';
import LocalesModalContent from '../LocalesModalContent';
import {
  primaryFontFamily,
  secondaryFontFamily,
} from '../../utils/brandHelpers';

const colors = css`
  background: ${({ theme }) => theme?.branding_json?.color_4};
  color: #fff;
  transition: background 0.2s ease-out;

  :hover {
    background: ${({ theme }) =>
      theme && theme.brand_name !== 'Achievement' && theme.branding_json
        ? theme.branding_json.color_8
        : '#0c4384'} !important;
    cursor: pointer !important;
  }

  :disabled {
    color: #fff;
    background: ${({ theme }) => theme?.branding_json?.color_4} !important;
  }
`;

export const BigBlueButton = styled(Button)`
  padding: 0 30px;
  text-align: center;
  white-space: nowrap;

  font-family: ${({ theme }) =>
    theme && theme.brand_name === 'PPMI'
      ? primaryFontFamily(theme, { fontWeight: 'bold' })
      : secondaryFontFamily(theme, { fontWeight: 'bold' })};
  font-size: ${20 / 16}rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 29px;
  border-radius: 5px;
  :hover {
    cursor: pointer !important;
  }

  ${colors};
  ${(p) => (p.hero ? `:disabled {${colors}}` : ``)};
`;

export const mobileOnly = css`
  @media (min-width: 801px) {
    display: none !important;
  }
`;

export const StyledNavLink = styled(Link)`
  ${NavLinkStyle};
`;

const StyledNavHashLink = styled(NavHashLink)`
  ${NavLinkStyle};
`;

const LogoNavLink = styled(StyledNavLink)`
  display: flex;
  opacity: 1;
  padding-top: 0;
  padding-bottom: 0;
  img {
    max-width: 100%;
  }
`;

export const Logo = withTheme(({ style = {}, theme }) => {
  if (theme?.brand_name === 'PPMI') {
    return <PPMILogo />;
  }

  if (
    theme?.brand_name &&
    theme.brand_name !== 'Achievement' &&
    theme?.branding_json?.logo_path
  ) {
    return <img src={theme.branding_json.logo_path} alt="Brand Logo" />;
  }

  return (
    <LogoAcm id="t-achievementLogo" alt="Achievement Logo" style={style} />
  );
});

export const LogoLink = ({ to, onClick, id, ariaLabel, ...logoProps }) => (
  <LogoNavLink to={to} onClick={onClick} id={id} aria-label={ariaLabel}>
    <Logo {...logoProps} />
  </LogoNavLink>
);

export const ContactUsLink = ({ padding, onClick }) => {
  const contactUs = useTranslated('components.header.links.contactUs');
  return (
    <NavLinkButton
      onClick={onClick}
      padding={padding}
      id="t-contactUs"
      className="navbar--item"
    >
      {contactUs}
    </NavLinkButton>
  );
};

export const DashboardLink = ({ onClick }) => {
  const dashboard = useTranslated('components.header.links.dashboard');
  return (
    <StyledNavLink
      id="t-dashboard"
      to="/manage/dashboard"
      className="navbar--item"
      onClick={onClick}
    >
      {dashboard}
    </StyledNavLink>
  );
};

export const SettingsLink = ({ onClick }) => {
  const settings = useTranslated('components.header.links.settings');
  return (
    <StyledNavLink
      id="t-settings"
      to="/manage/settings"
      className="navbar--item"
      onClick={onClick}
    >
      {settings}
    </StyledNavLink>
  );
};

export const FaqLink = ({ onClick }) => {
  const faq = useTranslated('components.header.links.faq');
  return (
    <StyledNavHashLink
      smooth
      to="#faq"
      id="t-faq"
      onClick={onClick}
      className="navbar--item"
      isActive={(match, location) => location.hash === `#faq`}
    >
      {faq}
    </StyledNavHashLink>
  );
};

export const LogInLink = ({ onClick }) => {
  const login = useTranslated('components.header.links.login');
  return (
    <StyledNavLink
      to="/log-in"
      id="t-logIn"
      onClick={onClick}
      className="navbar--item"
    >
      {login}
    </StyledNavLink>
  );
};

export const LogOutLink = ({ onClick }) => {
  const logout = useTranslated('components.header.links.logout');
  return (
    <NavLinkButton onClick={onClick} id="t-logOut" className="navbar--item">
      {logout}
    </NavLinkButton>
  );
};

export const SkipToContentLink = () => {
  const anchorRef = createRef<HTMLAnchorElement>();
  const skipToContent = useTranslated('components.header.skipToContent');

  function onScrollToView(event: MouseEvent) {
    event.preventDefault();
    const element = document.getElementById('content');
    anchorRef.current?.blur();
    element?.scrollIntoView({ behavior: 'smooth' });
    element?.focus({});
  }

  return (
    <Link
      className="sr-only skip-link"
      onClick={onScrollToView}
      onKeyDown={onScrollToView}
      ref={anchorRef}
    >
      {skipToContent}
    </Link>
  );
};

/**
 * @description Shows the link that display the current locale and the globe icon (only on web)
 * and when the locale link is clicked on it opens the modal to choose other languages.
 * @param {string} locale -  locale to be *displayed* on page load from the backend which is the source of truth on page load eg: 'es' , 'en_US'
 * this will be the displayed locale granted the url does not contain `local_override` as a query param, in which case we defer to that value.
 * @param {string} primaryLocale - the primary locale configured in study manager eg: 'es' , 'en_US'
 * @param {string[]} secondaryLocales - other locales that the study might be configured in.
 * @returns {JSX.Element}
 */
export const LanguagePicker = ({
  locale,
  primaryLocale = '',
  secondaryLocales,
  participantLocale,
}) => {
  const headerText = useTranslated(
    'components.header.languagePickerModal.title',
  );
  const [isOpen, setIsOpen] = useState(false);
  const localOverride = useSetLocaleOverride();
  const trueLocale = localOverride || participantLocale || locale; // we could get a locale override from a query param.rueLocale = participantLocale;)
  // We now build the array of unique locales (filtering out the current one being displayed `localeText` ) to show in the modal.
  // `localeText` can change based on the user's selection, so it can be any of the values from locale, primaryLocale or secondaryLocales
  const [localeText, setLocaleText] = useState(() =>
    getDisplayTextFromLocale(trueLocale),
  );

  const localesForModal = [
    ...new Set([primaryLocale, locale, trueLocale, ...secondaryLocales]),
  ];

  useEffect(() => {
    setLocaleText(trueLocale);
  }, [trueLocale]);

  /**
   * @description Functions that help us open and close the language picker modal
   */
  const openModal = () => setIsOpen((open) => true);
  const closeModal = () => setIsOpen((open) => false);

  return (
    <>
      <NavLinkDiv
        padding={'10px 5px'}
        to=""
        data-test-id="language_picker_link"
        onClick={openModal}
      >
        {!isMobile && <Globe style={{ marginRight: 5 }} />}
        <span>{localeText.toUpperCase()}</span>
      </NavLinkDiv>
      <BasicStyledModal
        isOpen={isOpen}
        closeModal={closeModal}
        header={headerText}
      >
        <LocalesModalContent
          selectedLocale={localeText}
          localesForModal={localesForModal}
          setLocaleText={setLocaleText}
          closeModal={closeModal}
        />
      </BasicStyledModal>
    </>
  );
};
