const parser = new DOMParser();

export const createCustomScriptTag = (scriptAsString: string, head: boolean) => {
  const customElements = parser.parseFromString(scriptAsString, 'text/html');

  const scripts = customElements?.getElementsByTagName('script');

  const customScripts = Array.from(scripts).map((script) => {
    let attributes = {
      type:
        script.attributes.getNamedItem('type')?.nodeValue ?? 'text/javascript',
    };
    if (script.attributes.getNamedItem('src')?.nodeValue) {
      attributes['src'] = script.attributes.getNamedItem('src')?.nodeValue;
    }
    if (script.attributes.getNamedItem('async')?.nodeValue) {
      attributes['async'] = script.attributes.getNamedItem('async')?.nodeValue;
    }
    return {
      attributes,
      children: Array.from(script.childNodes).map((c) => c.textContent),
    };
  });

  // Indicate that the other custom JavaScript has been injected.
  const promiseObject = head ? 'window.customHead' : 'window.customBody';
  const finalizerScript = {
    attributes: { type: 'text/javascript' },
    children: [`${promiseObject}.defined()`]
  }
  customScripts.push(finalizerScript);

  return customScripts;
};

export const createCustomMetaTag = (metaAsString: string) => {
  if (!metaAsString) return;
  const customElements = parser.parseFromString(metaAsString, 'text/html');

  const metaTags = customElements?.getElementsByTagName('meta');
  const customMetaTags = Array.from(metaTags).map((tag) => {
    let attributes = {};
    if (tag.attributes.getNamedItem('name')?.nodeValue) {
      attributes['name'] = tag.attributes.getNamedItem('name')?.nodeValue;
    }
    if (tag.attributes.getNamedItem('content')?.nodeValue) {
      attributes['content'] = tag.attributes.getNamedItem('content')?.nodeValue;
    }
    return {
      attributes,
    };
  });

  return customMetaTags;
};

export const createCustomLinkTag = (linkAsString: string) => {
  if (!linkAsString) return;

  const customElements = parser.parseFromString(linkAsString, 'text/html');

  const linkTags = customElements?.getElementsByTagName('link');
  const customLinkTags = Array.from(linkTags).map((tag) => {
    let attributes = {};
    if (tag.attributes.getNamedItem('rel')?.nodeValue) {
      attributes['rel'] = tag.attributes.getNamedItem('rel')?.nodeValue;
    }
    if (tag.attributes.getNamedItem('href')?.nodeValue) {
      attributes['href'] = tag.attributes.getNamedItem('href')?.nodeValue;
    }
    return {
      attributes,
    };
  });

  return customLinkTags;
};

export const createCustomStyleTag = (styleAsString: string) => {
  if (!styleAsString) return;

  const customElements = parser.parseFromString(styleAsString, 'text/html');

  const styleTags = customElements?.getElementsByTagName('style');

  const customStyleTags = Array.from(styleTags).map((tag) => {
    let attributes = {};
    if (tag.attributes.getNamedItem('media')?.nodeValue) {
      attributes['media'] = tag.attributes.getNamedItem('media')?.nodeValue;
    }
    return {
      attributes,
      children: Array.from(tag.childNodes).map((c) => c.textContent),
    };
  });

  return customStyleTags;
};
