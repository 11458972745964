"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateWeight = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _utils = require("../../utils");
var _excluded = ["name"];
var validateWeight = exports.validateWeight = function validateWeight(_ref) {
  var name = _ref.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value) {
    if (value && value[name] && value["".concat(name, "_units")] === 'stones_as_pounds') {
      var stones_max = rest.stones_max,
        stones_min = rest.stones_min,
        stones_pounds_max = rest.stones_pounds_max,
        stones_pounds_min = rest.stones_pounds_min;
      var stones = value[name].stones;
      var pounds = value[name].pounds;
      var combinedAnswerValues = Number(stones) * 14 + Number(pounds);
      var combinedMaxValidationValues = Number(stones_max) * 14 + Number(stones_pounds_max);
      var combinedMinValidationValues = Number(stones_min) * 14 + Number(stones_pounds_min);
      if (!(0, _utils.isEmpty)(stones_max) || !(0, _utils.isEmpty)(stones_min)) {
        var isStonesinRange = (0, _utils.isEmpty)(stones_min) && (0, _utils.isEmpty)(stones_max) ? true : (0, _utils.isEmpty)(stones_min) ? Number(stones) <= Number(stones_max) : (0, _utils.isEmpty)(stones_max) ? Number(stones) >= Number(stones_min) : Number(stones) >= Number(stones_min) && Number(stones) <= Number(stones_max);
        var isStonesPoundsinRange = (0, _utils.isEmpty)(stones_pounds_min) && (0, _utils.isEmpty)(stones_pounds_max) ? true : (0, _utils.isEmpty)(stones_pounds_min) ? Number(pounds) <= Number(stones_pounds_max) : (0, _utils.isEmpty)(stones_pounds_max) ? Number(pounds) >= Number(stones_pounds_min) : Number(pounds) >= Number(stones_pounds_min) && Number(pounds) <= Number(stones_pounds_max);
        if (!(0, _utils.isEmpty)(stones) && !isStonesinRange || !(0, _utils.isEmpty)(pounds) && !isStonesPoundsinRange) {
          var messageKey = (0, _utils.isEmpty)(stones_min) && (0, _utils.isEmpty)(stones_max) && !(0, _utils.isEmpty)(stones_pounds_min) && !(0, _utils.isEmpty)(stones_pounds_max) ? 'fields.weightInternational.errorMsgPoundsMinMax' : !(0, _utils.isEmpty)(stones_min) && !(0, _utils.isEmpty)(stones_max) && (0, _utils.isEmpty)(stones_pounds_min) && (0, _utils.isEmpty)(stones_pounds_max) ? 'fields.weightInternational.errorMsgOnlyStonesMinMax' : (0, _utils.isEmpty)(stones_min) && (0, _utils.isEmpty)(stones_pounds_min) ? 'fields.weightInternational.errorMsgStonesPoundsMax' : (0, _utils.isEmpty)(stones_max) && (0, _utils.isEmpty)(stones_pounds_max) ? 'fields.weightInternational.errorMsgStonesPoundsMin' : 'fields.weightInternational.errorMsgStonesMinMax';
          return (0, _i18n.globalTranslate)(messageKey, {
            min_stones: stones_min,
            min_pounds: stones_pounds_min,
            max_stones: stones_max,
            max_pounds: stones_pounds_max
          });
        }
      }
      if ((0, _utils.isEmpty)(pounds)) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorEmptyPounds');
      }
      if (Number(pounds) < 0 || Number(pounds) >= 14) {
        var keyMessage = !(0, _utils.isEmpty)(stones_min) && !(0, _utils.isEmpty)(stones_max) ? 'fields.weightInternational.errorMsgStonesMinMax' : 'fields.weightInternational.errorMsgPoundsMinMax';
        return (0, _i18n.globalTranslate)(keyMessage, {
          min_stones: rest.stones_min,
          max_stones: rest.stones_max,
          min_pounds: stones_pounds_min || 0,
          max_pounds: stones_pounds_max || 13
        });
      }
      if (!(0, _utils.isEmpty)(stones) && String(stones).includes('.') || !(0, _utils.isEmpty)(pounds) && String(pounds).includes('.')) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgDecimalsStonesPounds');
      }
      if (stones_min && stones_max && stones_pounds_min && stones_pounds_max) {
        if (combinedAnswerValues < combinedMinValidationValues || combinedAnswerValues > combinedMaxValidationValues) {
          return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgStonesMinMax', {
            min_stones: rest.stones_min,
            min_pounds: rest.stones_pounds_min,
            max_stones: rest.stones_max,
            max_pounds: rest.stones_pounds_max
          });
        }
      } else {
        if (value[name].pounds === undefined || value[name].pounds === null || String(value[name].pounds).length === 0) {
          return (0, _i18n.globalTranslate)('fields.weightInternational.errorEmptyPounds');
        }
      }
      if (!(0, _utils.isEmpty)(stones) && !(0, _utils.isEmpty)(pounds) && combinedAnswerValues < 1) {
        return (0, _i18n.globalTranslate)('validations.greaterThanZero');
      }
      return undefined;
    }
    if (value && value[name] && value["".concat(name, "_units")] === 'pounds') {
      var _rest$pounds_min, _rest$pounds_max;
      var pounds_min = (_rest$pounds_min = rest === null || rest === void 0 ? void 0 : rest.pounds_min) !== null && _rest$pounds_min !== void 0 ? _rest$pounds_min : undefined;
      var pounds_max = (_rest$pounds_max = rest === null || rest === void 0 ? void 0 : rest.pounds_max) !== null && _rest$pounds_max !== void 0 ? _rest$pounds_max : undefined;
      var weightInPounds = value[name];
      if (!(0, _utils.isEmpty)(pounds_max) || !(0, _utils.isEmpty)(pounds_min)) {
        if ((0, _utils.isEmpty)(pounds_max) && !(0, _utils.isEmpty)(pounds_min)) {
          if (Number(weightInPounds) < Number(pounds_min)) return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgPoundsMin', {
            min_pounds: Number(pounds_min)
          });
        }
        if ((0, _utils.isEmpty)(pounds_min) && !(0, _utils.isEmpty)(pounds_max)) {
          if (Number(weightInPounds) > Number(pounds_max)) return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgPoundsMax', {
            max_pounds: Number(pounds_max)
          });
        }
        if (Number(weightInPounds) > Number(pounds_max) || Number(weightInPounds) < Number(pounds_min)) {
          return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgPoundsMinMax', {
            max_pounds: pounds_max,
            min_pounds: pounds_min
          });
        }
        if (Number(value && value[name]) < 1) {
          return (0, _i18n.globalTranslate)('validations.greaterThanZero');
        }
      }
      if (!(0, _utils.isEmpty)(weightInPounds) && String(weightInPounds).includes('.')) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgDecimalsPounds');
      }
      if (!(0, _utils.isEmpty)(weightInPounds) && Number(weightInPounds) < 1) {
        return (0, _i18n.globalTranslate)('validations.greaterThanZero');
      }
      return undefined;
    }
    if (value && value[name] && value["".concat(name, "_units")] === 'kilograms') {
      var _rest$kg_min, _rest$kg_max;
      var kg_min = (_rest$kg_min = rest === null || rest === void 0 ? void 0 : rest.kg_min) !== null && _rest$kg_min !== void 0 ? _rest$kg_min : undefined;
      var kg_max = (_rest$kg_max = rest === null || rest === void 0 ? void 0 : rest.kg_max) !== null && _rest$kg_max !== void 0 ? _rest$kg_max : undefined;
      var weightInKilograms = value[name];
      if (!(0, _utils.isEmpty)(rest === null || rest === void 0 ? void 0 : rest.kg_min) || !(0, _utils.isEmpty)(rest === null || rest === void 0 ? void 0 : rest.kg_max)) {
        var isInRange = (0, _utils.isEmpty)(kg_max) && (0, _utils.isEmpty)(kg_min) ? false : (0, _utils.isEmpty)(kg_max) ? Number(weightInKilograms) >= Number(kg_min) : (0, _utils.isEmpty)(kg_min) ? Number(weightInKilograms) <= Number(kg_max) : Number(weightInKilograms) >= Number(kg_min) && Number(weightInKilograms) <= Number(kg_max);
        if (!isInRange) {
          var _messageKey = (0, _utils.isEmpty)(kg_max) ? 'fields.weightInternational.errorMsgKgMin' : (0, _utils.isEmpty)(kg_min) ? 'fields.weightInternational.errorMsgKgMax' : 'fields.weightInternational.errorMsgKgMinMax';
          return (0, _i18n.globalTranslate)(_messageKey, {
            max_kg: rest.kg_max,
            min_kg: rest.kg_min
          });
        }
      }
      if (!(0, _utils.isEmpty)(weightInKilograms) && String(weightInKilograms).includes('.')) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgDecimalsKilograms');
      }
      if (!(0, _utils.isEmpty)(weightInKilograms) && Number(weightInKilograms) < 1) {
        return (0, _i18n.globalTranslate)('validations.greaterThanZero');
      }
    }
    return undefined;
  };
};
var WeightValidations = function WeightValidations(props) {
  return [validateWeight(props)];
};
var _default = exports["default"] = WeightValidations;