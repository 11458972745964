"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateRanking = exports.isEmpty = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.filter.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _excluded = ["name", "required"];
var isEmpty = exports.isEmpty = function isEmpty(value) {
  return value === '' || value === undefined || value === null;
};
var validateRanking = exports.validateRanking = function validateRanking(_ref) {
  var name = _ref.name,
    required = _ref.required,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value, allValues) {
    if (value === 'set_by_exclusive_input' || !isEmpty(allValues === null || allValues === void 0 ? void 0 : allValues["".concat(name, "_exclusive")])) return undefined;
    var answersToRank = rest.ranking_component_answers;
    var rankedOptions = (value === null || value === void 0 ? void 0 : value.filter(function (rankedOption) {
      return rankedOption !== '';
    })) || [];
    var minRankedOptions = rest.min_value;
    var maxRankedOptions = rest.max_value;
    var everyAnswerShouldBeRanked = answersToRank.length <= maxRankedOptions;
    // Optional and there is not ranked options
    if (!required && rankedOptions.length === 0) {
      return undefined;
    } // Else assuming user will answer, should follow the min
    if (rankedOptions.length < minRankedOptions) {
      return (0, _i18n.globalTranslate)('fields.ranking.requiredMinOptions', {
        min: minRankedOptions
      });
    } else if (everyAnswerShouldBeRanked && rankedOptions.length < answersToRank.length) {
      return (0, _i18n.globalTranslate)('fields.ranking.shouldRankAllOptions');
    }
    return undefined;
  };
};
var RankingValidations = function RankingValidations(props) {
  return [validateRanking(props)];
};
var _default = exports["default"] = RankingValidations;