// Defines a shared promise that allows certain functionality to be blocked
// until all the custom JavaScript has been loaded.
export class CustomDependencyManager {
  private isDefined = false;
  private resolveSharedPromise!: () => void;
  private rejectSharedPromise!: (reason?: any) => void;
  private sharedPromise: Promise<void>;
  private dependencyPromises: Promise<any>[] = [];

  constructor(private name: string) {
    this.sharedPromise = new Promise<void>((resolve, reject) => {
      this.resolveSharedPromise = resolve;
      this.rejectSharedPromise = reject;
    });
  }

  /**
   * Indicate that the custom config has been loaded.
   */
  defined() {
    if (this.isDefined) {
      console.warn(`${this.name} is already defined.`);
      return;
    }
    this.isDefined = true;

    // Resolve the shared promise when all dependencies are complete
    Promise.all(this.dependencyPromises)
      .then(() => this.resolveSharedPromise())
      .catch((err) => this.rejectSharedPromise(err));
  }

  /**
   * Add an additional dependency that must be loaded.
   * @param promise A promise to wait on before resolving
   */
  addDependency(promise: Promise<any>) {
    if (this.isDefined) {
      console.warn(`Cannot add dependencies after ${this.name} is defined.`);
      return;
    }
    if (!(promise instanceof Promise)) {
      throw new Error("addDependency expects a Promise.");
    }
    this.dependencyPromises.push(promise);
  }

  /**
   * Wait for all dependencies to be loaded.
   * @returns A promise that resolves when all conditions are met
   */
  async loaded(): Promise<void> {
    return this.sharedPromise;
  }
}
